.headerMenu {
  align-items: center;
  box-sizing: border-box;
  padding: 0 16px;
  height: 60px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  line-height: normal;
  top: 0;
  width: 100%;
  background-color: #fff;
}

.bannerImage {
  max-height: 300px;
  width: 100%;
  object-fit: cover;
}
