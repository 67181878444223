@import url('https://fonts.googleapis.com/css?family=Rubik:400,500,700&display=swap');
@import 'styles/global';

body {
  background-color: #f4f5f8;
}

iframe {
  border: none;
}

h1,
h2,
h3,
h4 {
  font-weight: 400;
}

h1 {
  font-size: 1.6rem;
}

h2 {
  font-size: 1.2rem;
}

.ant-btn-round {
  font-size: 1rem;
}

.ant-menu-horizontal {
  border-bottom: 0;
}

p:empty {
  display: none;
}

#root {
  height: 100%;
}
